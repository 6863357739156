import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'

import LegalNoticeSeoImage from '../assets/images/news.jpg'

class LegalNotice extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query LegalNoticeMeta {
                    site {
                        siteMetadata {
                            title
                            description
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='legal'>
                    <Seo title={`Legal Notice | ${data.site.siteMetadata.title}`} description={`The information on this website is for informational purposes only and should not be considered legal advice or legal opinion.`} image={LegalNoticeSeoImage} keywords={[``]} />
                    <Banner title='Legal Notice' subTitle='' titleColor='#f58220' subTitleColor='#f58220' image={LegalNoticeSeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <p>The information on this website is for informational purposes only and should not be considered legal advice or legal opinion.</p>
                                    <p>Transmission, receipt, or use of the information on this website shall not constitute or create an attorney-client relationship. An attorney-client relationship can only be established after speaking with one of our attorneys and signing a written retainer agreement.</p>
                                    <p>All information on this website is copyrighted.</p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default LegalNotice